import config from '../../../config'
import axios from 'axios'

const getDownloadLink = async (id, sessionId) => {
    const response = await axios.get(`${config.API_BASE_URL}/magicfans/download?id=${id}&sessionId=${sessionId}`, {
        withCredentials: true
    })

    return response
}

export default getDownloadLink