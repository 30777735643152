import ElementsStyles from '../../../assets/css/Elements.css'

const PrimaryButton = ({ children, onClick }) => {
    return (
        <button
            className="btn btn-primary btn-lg px-4 gap-3 rounded-4"
            type="button"
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default PrimaryButton