import { useEffect } from 'react'

import { LoginLoading } from '../features/Login'

const Login = () => {

    useEffect(() => {
        document.title = 'MagicFans - Login'
    }, [])

    return (
        <>
            <LoginLoading />
        </>
    )
}

export default Login