import { useParams } from 'react-router-dom'

import { NavigationBar, Footer } from '../components/Layout'
import { Profile } from '../features/ModelProfile'
import Alert from '../components/Misc/Alert'


const ModelProfile = () => {
  const parameters = useParams()

  return (
    <>
      <NavigationBar />
      <Alert />
      <Profile />
      <Footer />
    </>
  )
}

export default ModelProfile