import config from '../../../config'

import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import getPoints from '../../../api/getPoints'
import logout from '../../../api/logout'

const MyAccount = () => {
    const [cookies, setCookie, removeCookie] = useCookies();

    const [points, setPoints] = useState(0)

    const handleGetPoints = async () => {
        const response = await getPoints({ sessionId: cookies.sessionId })

        console.log(response)
        if (response.data.error) {
            removeCookie('sessionId')
            removeCookie('username')
            //window.location.replace('/')
        }

        setPoints(response.data.points)
    }

    useEffect(() => {
        handleGetPoints()
    }, [])

    const handleLogout = async () => {
        await logout()

        removeCookie('sessionId')
        removeCookie('username')
        window.location.replace(`${config.MAGICPOINTS_WEBSITE_URL}/logout?source=magicfans`)
    }

    return (
        <>
            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ zIndex: 1 }}>
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="d-flex align-items-center">
                                <i className="bi bi-coin me-1"></i>
                                <span>Balance</span>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end animate slideIn" aria-labelledby="navbarDropdown">
                            <div className="d-flex flex-column p-2 text-white text-center">
                                <span className="lead fs-5">Balance</span>
                                <span className="mb-2 special-text">{points} Points</span>
                                <a className="btn btn-primary mt-2" href={config.MAGICPOINTS_WEBSITE_URL + '/buy-points'} target="_blank">Buy Points</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ zIndex: 1 }}>
                <ul className="navbar-nav ms-auto">
                    <li className="btn btn-primary nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="bi bi-person"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end animate slideIn" aria-labelledby="navbarDropdown">
                            <div className="d-flex flex-column p-2 text-white text-center">
                                <span className="lead mb-2 fs-5">{cookies.username}</span>
                                <a className="btn btn-primary mt-2" href={config.MAGICPOINTS_WEBSITE_URL} target="_blank">Dashboard</a>
                                <button className="btn btn-link mt-2" onClick={handleLogout}>Logout</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default MyAccount