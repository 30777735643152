import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { PrimaryButton } from '../../../components/Elements/Button'

const ContactInformation = () => {
    const [copy, setCopy] = useState('Copy')

    const handleCopyClick = () => {
        setCopy('Copied')

        setTimeout(() => {
            setCopy('Copy')
        }, 2000)
    }

    return (
        <div className="container wrapper">
            <div className="px-4 py-0 my-5 text-center">
                <h1 className="display-6 fw-bold">MagicFans - Contact</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4 fs-5">To get in touch with us, please use the email below.</p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">

                        <input type="text" className="form-control shadow-none rounded-4" readOnly value="magicgen@protonmail.com" />
                        <CopyToClipboard text={'magicgen@protonmail.com'} onCopy={handleCopyClick}>
                            {/* <button type="button" className="btn btn-primary btn-lg px-4 gap-3">{copy}</button> */}
                            <PrimaryButton onClick={handleCopyClick}>{copy}</PrimaryButton>
                        </CopyToClipboard>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInformation