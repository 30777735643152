import React from 'react'

const LoadingSpinner = () => {
    return (
        <div className="wrapper d-flex justify-content-center align-items-center">
            <div className="spinner-grow" role="status" style={{ width: '3rem', height: '3rem', color: '#3C79F5' }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default LoadingSpinner