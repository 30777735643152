import config from '../../../config'
import axios from 'axios'

const fetchModel = async (modelSlug) => {
    const response = await axios.get(config.API_BASE_URL + '/magicfans/get-model?name=' + modelSlug)

    return response
}

export default fetchModel
