import { useCookies } from 'react-cookie'

const useIsAuthenticated = () => {
    const [cookies, setCookie] = useCookies()

    if (cookies.sessionId) {
        return true
    }
    return false
}

export default useIsAuthenticated