import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Home from './pages/Home'
import Contact from './pages/Contact'
import ModelProfile from './pages/ModelProfile'
import Login from './pages/Login'
import GenerateDownload from './pages/GenerateDownload'
import Download from './pages/Download'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/search' element={<Home />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/model' element={<ModelProfile />} />
        <Route exact path='/model/:slug_url' element={<ModelProfile />} />
        <Route exact path='/login' element={<Login />} />
        
        <Route exact path='/generate-download' element={<GenerateDownload />} />
        <Route exact path='/generate-download/:slug_url' element={<GenerateDownload />} />

        <Route exact path='/download' element={<Download />} />
        <Route exact path='/download/:id' element={<Download />} />
      </Routes>
    </Router>
  );
}

export default App;
