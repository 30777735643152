import { useNavigate } from 'react-router-dom'

const useFiltersParams = () => {
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(window.location.search)

    const getParameterValue = (parameter, defaultValue) => {
        return searchParams.get(parameter) || defaultValue
    }

    const updateParameterValue = (parameter, newValue) => {
        searchParams.set(parameter, newValue)
        return navigate({ search: `?${searchParams.toString()}` })
    }

    return [getParameterValue, updateParameterValue]
}

export default useFiltersParams
