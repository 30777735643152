import config from '../../../config'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MdDownload, MdSearch, MdReport } from 'react-icons/md'

import useAlert from '../../../stores/useAlert'

import LoadingSpinner from '../../../components/Misc/LoadingSpinner'

import fetchModel from '../api/fetchModel'
import reportBrokenLink from '../api/reportBrokenLink'

import useIsAuthenticated from '../../../customhooks/useIsAuthenticated'

const Profile = () => {
    const isAuthenticated = useIsAuthenticated()
    const setAlert = useAlert((state) => state.setAlert)
    const hideAlert = useAlert((state) => state.hideAlert)

    const { slug_url } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [modelProfile, setModelProfile] = useState({
        id: 0,
        name: '',
        image: '',
        downloadUrl: '',
        slugUrl: ''
    })
    const [isReportBrokenLinkDisabled, setisReportBrokenLinkDisabled] = useState(false)

    useEffect(() => {
        const fetchModelProfile = async () => {
            const response = await fetchModel(slug_url)

            if (response.data.error || response.data.data.length === 0) {
                window.location.replace('/')
            }

            if (!response.data.error) {
                document.title = `MagicFans - ${response.data.data.name}`

                setModelProfile({
                    id: response.data.data.id,
                    name: response.data.data.name,
                    image: response.data.data.image,
                    downloadUrl: response.data.data.download_url,
                    slugUrl: response.data.data.slug_url
                })
            }
        }

        fetchModelProfile()
    }, [])

    const handleReportBrokenLink = async () => {
        const response = await reportBrokenLink(modelProfile.id)

        setAlert(response.data.message)
        setisReportBrokenLinkDisabled(true)

    }
    return (
        <>
            {isLoading ?
                <LoadingSpinner /> :

                <div className="container col-xl-10 px-4 py-5">
                    <p className="col-lg-10 fs-4 text-white text-truncate">{modelProfile.name}</p>
                    <hr className="solid" />
                    <div className="row g-lg-5 pb-5 pt-1">
                        <div className="col-md-10 mx-auto col-lg-3 mb-3">
                            {/* <a href={modelProfile.downloadUrl} className="btn btn-primary mb-3 w-100 rounded-4">Download <MdDownload /></a> */}
                            <a href={`/generate-download/${modelProfile.slugUrl}`} className="btn btn-primary mb-3 w-100 rounded-4" target={'_blank'}>Download <MdDownload /></a>
                            {isAuthenticated &&
                                <>
                                    <a href={`/generate-download/${modelProfile.slugUrl}?ad_free=true`} className="btn btn-link rounded-4" style={{ width: '100%' }} target={'_blank'}>Ad-free for <span class="special-text">1 point</span></a>
                                    <hr className="solid" />
                                </>
                            }
                            <button className="btn btn-outline-danger mb-3 w-100 rounded-4" disabled={isReportBrokenLinkDisabled} onClick={handleReportBrokenLink}>Report Broken Link <MdReport /></button>
                        </div>
                        <div className="col-lg-9 text-center text-lg-start">
                            <img src={!modelProfile.image ? config.PLACEHOLDER_IMAGE : modelProfile.image} className="img-fluid rounded-4" height="1080px" width="1080px" />
                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default Profile