import config from '../../../config'

import useIsAuthenticated from '../../../customhooks/useIsAuthenticated'

import { MdDownload } from 'react-icons/md'

const FeedCard = ({ name, image, downloadUrl, slugUrl }) => {
    const isAuthenticated = useIsAuthenticated()

    const handleAdFree = () => {
        console.log(slugUrl)
    }

    return (
        <div className="col-lg-3 my-3">
            <div className="card h-100 shadow-sm">
                <img src={!image ? config.PLACEHOLDER_IMAGE : image} className="card-img-top" />
                <div className="card-img-elements">
                    {/* <span class="badge main-color fs-6">Trending</span> */}
                </div>
                <div className="card-body">
                    <h5 className="card-title text-center">
                        <a className="text-decoration-none text-white" href={'/model/' + slugUrl}>{name}</a>
                    </h5>
                    {/* <a className="card-title text-center fs-5" href={'/model/' + slugUrl}>{name}</a> */}
                    <div className="text-center">
                        <a href={`/generate-download/${slugUrl}`} className="btn btn-primary rounded-4" style={{ width: '100%' }} target={'_blank'}>Download <MdDownload /></a>

                        {isAuthenticated &&
                            <a href={`/generate-download/${slugUrl}?ad_free=true`} className="btn btn-link rounded-4" style={{ width: '100%' }} target={'_blank'}>Ad-free for <span class="special-text">1 point</span></a>
                        }

                        {/* <button className="btn btn-link rounded-4" style={{ width: '100%' }}>Ad-free for <span class="special-text">1 point</span></button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedCard