import config from '../../../config'
import axios from 'axios'

const fetchContent = async ({ keywords, category, page }) => {
    if (category === 'all') {
        category = ''
    }

    const response = await axios.get(config.API_BASE_URL + '/magicfans/get-content?keywords=' + keywords + '&category=' + category + '&page=' + page, { withCredentials: true })

    return response
}

export default fetchContent
