import { useEffect } from 'react'

import { NavigationBar, Footer } from '../components/Layout'
import { ContactInformation } from '../features/Contact'

const Contact = () => {

    useEffect(() => {
        document.title = 'MagicFans - Contact'
    }, [])
    
    return (
        <>
            <NavigationBar />
            <ContactInformation />
            <Footer />
        </>
    )
}

export default Contact