import config from '../config'
import axios from 'axios'

const getPoints = async({ sessionId }) => {
    //const response = await axios.get(config.API_BASE_URL + '/magicpoints/user-points', { withCredentials: true })
    const response = await axios.get(`${config.API_BASE_URL}/magicpoints/user-points?sessionId=${sessionId}`, { withCredentials: true })

    return response
}

export default getPoints