import config from '../../../config'

import magicgen from '../../../assets/favicons/magicgen.png'
import magicfans from '../../../assets/favicons/magicfans.png'
import magicpoints from '../../../assets/favicons/magicpoints.png'

const Products = () => {
    return (
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown">
                    <a className="nav-link text-center" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="bi bi-grid"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end animate slideIn products" aria-labelledby="navbarDropdown" style={{ minWidth: '250px' }}>
                        <div className="col-lg-12 mx-auto px-3">
                            <div className="row d-flex g-3 text-center">
                                <div className="col-lg-6 p-1">
                                    <a href={config.MAGICGEN_WEBSITE_URL} target="_blank">
                                        <img src={magicgen} width="50" height="50" />
                                        <p className="h6">MagicGen</p>
                                    </a>
                                </div>
                                <div className="col-lg-6 p-1">
                                    <a href={config.MAGICFANS_WEBSITE_URL} target="_blank">
                                        <img src={magicfans} width="50" height="50" />
                                        <p className="h6">MagicFans</p>
                                    </a>
                                </div>
                                <div className="col-lg-6 p-1">
                                    <a href={config.MAGICPOINTS_WEBSITE_URL} target="_blank">
                                        <img src={magicpoints} width="50" height="50" />
                                        <p className="h6">MagicPoints</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Products