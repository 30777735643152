import React, { useEffect } from 'react'
import useFiltersParams from '../../../customhooks/useFiltersParams'

import logo from '../../../assets/logo.png'

import validateToken from '../api/validateToken'

import { useCookies } from 'react-cookie'

const LoginLoading = () => {
    const [getParameterValue, setParameterValue] = useFiltersParams()
    const [cookies, setCookie] = useCookies(['sessionId']);

    const handleLogin = async () => {
        const tokenId = getParameterValue('token_id', null);

        if (!tokenId) {
           return window.location.replace('/')
        }

        const tokenResponse = await validateToken({ tokenId })
        console.log(tokenResponse)
        if (tokenResponse.data.error) {
            return window.location.replace('/')
        }

        setCookie('sessionId', tokenResponse.data.sessionId)
        setCookie('username', tokenResponse.data.username)
        return window.location.replace('/')
    }
    useEffect(() => {
        handleLogin()
    }, [])
    return (
        <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid auth-wrapper text-center" style={{ maxWidth: '500px' }}>
                {/* <div className="alert alert-primary text-center" role="alert">
                    message
                </div> */}

                <img src={logo} className="mb-3" alt="logo" width="202.8" height="39" />

                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginLoading