import { DownloadContent } from '../features/Download'

const Download = () => {
    return (
        <>
            <DownloadContent />
        </>
    )
}

export default Download