import { Generate } from '../features/GenerateDownload'

const GenerateDownload = () => {
    return (
        <>
            <Generate />
        </>
    )
}

export default GenerateDownload