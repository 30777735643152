import { useState, useEffect } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'

import useFiltersParams from '../../../customhooks/useFiltersParams'
import usePage from '../../../stores/usePage'
import useAlert from '../../../stores/useAlert'

import LoadingSpinner from '../../../components/Misc/LoadingSpinner'
import FeedCard from './FeedCard'
import NoResultsFound from './NoResultsFound'

import fetchContent from '../api/fetchContent'

import FeedStyles from '../../../assets/css/Feed.css'

const Feed = () => {
    
    const [getParameterValue, setParameterValue] = useFiltersParams()
    const keywords = getParameterValue('keywords') || ''
    const category = getParameterValue('category') || ''

    const setAlert = useAlert((state) => state.setAlert)
    const hideAlert = useAlert((state) => state.hideAlert)
    const page = usePage((state) => state.page)
    const setPage = usePage((state) => state.setPage)
    const [content, setContent] = useState([])
    //const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [exactSearchFound, setExactSearchFound] = useState(true)

    useEffect(() => {
        const fetchInitialContent = async () => {
            setIsLoading(true)

            const response = await fetchContent({
                keywords,
                category,
                page
            })

            if (response.data.error) {
                setAlert('There was an error.');
                setIsLoading(false)
            }

            if (!response.data.error) {
                hideAlert()
                const array = Object.values(response.data.data)

                setContent(array)
                //setPage(previousPage => previousPage + 1)
                setPage(page + 1)
                setIsLoading(false)
                setExactSearchFound(response.data.exactSearchFound)
            }
        }

        fetchInitialContent()
    }, [keywords, category])

    const loadMore = async () => {
        setIsLoadingMore(true)

        const response = await fetchContent({
            keywords,
            category,
            page
        })

        if (!response.data.error) {
            const array = Object.values(response.data.data)

            setContent([...content, ...array])
            //setPage(previousPage => previousPage + 1)
            console.log(page + 1)
            setPage(page + 1)
            setIsLoadingMore(false)
        }
    }
    

    useBottomScrollListener(() => {
        if (!isLoadingMore) {
            loadMore()
        }
    })

    return (
        <div className='container px-4 py-4'>
            <div className='col-lg-12 mx-auto' >
                {/* <LoadingSpinner /> */}
                {isLoading ?
                    <LoadingSpinner /> :
                    <>
                        {!exactSearchFound &&
                            <NoResultsFound />
                        }
                        <div className='row d-flex g-3'>
                            {

                                content.map(data => {
                                    return (
                                        <FeedCard
                                            name={data.name}
                                            image={data.image}
                                            downloadUrl={data.download_url}
                                            slugUrl={data.slug_url}
                                            key={data.id}
                                        />
                                    )
                                })

                            }

                        </div>
                    </>
                }
                {isLoadingMore && <LoadingSpinner />}
            </div>
        </div>
    )
}

export default Feed