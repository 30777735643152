import config from '../../../config'
import axios from 'axios'

const generateDownloadLink = async (modelSlug, adFree, sessionId) => {
    //const response = await axios.get(config.API_BASE_URL + '/magicfans/generate-download' + modelSlug)
    const response = await axios.get(`${config.API_BASE_URL}/magicfans/generate-download?model=${modelSlug}&ad_free=${adFree}&sessionId=${sessionId}`, {
        withCredentials: true
    })

    return response
}

export default generateDownloadLink