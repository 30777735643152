const config_dev = {
    PLACEHOLDER_IMAGE: 'https://magicfans.nyc3.digitaloceanspaces.com/loading.png',

    API_BASE_URL: 'http://localhost:3009',
    MAGICGEN_WEBSITE_URL: 'http://localhost:3005',
    MAGICFANS_WEBSITE_URL: 'http://localhost:3000',
    MAGICPASTE_WEBSITE_URL: 'http://localhost:3010',
    MAGICPOINTS_WEBSITE_URL: 'http://localhost:3008'
}

const config = {
    PLACEHOLDER_IMAGE: 'https://magicfans.nyc3.digitaloceanspaces.com/loading.png',

    API_BASE_URL: 'https://api.magicgen.xyz/v2',
    MAGICGEN_WEBSITE_URL: 'https://magicgen.xyz',
    MAGICFANS_WEBSITE_URL: 'https://magicfans.cc',
    MAGICPASTE_WEBSITE_URL: 'https://magicpaste.cc',
    MAGICPOINTS_WEBSITE_URL: 'https://magicpoints.cc'
}

export default config