import config from '../../../config'
import axios from 'axios'

const reportBrokenLink = async (id) => {
    const response = await axios.get(config.API_BASE_URL + '/magicfans/report-broken-link?id=' + id)

    return response
}

export default reportBrokenLink
