import React from 'react'

const NoResultsFound = () => {
    return (
        <>
            <h1 className="fs-4 fw-bold text-center">No results found for your search.</h1>
            <h3 className="fs-6 text-center">Please try a different search.</h3>

            <h3 className="fs-4 fw-bold pt-4">You might also like:</h3>
        </>
    )
}

export default NoResultsFound