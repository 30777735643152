import useAlert from '../../stores/useAlert'

const Alert = () => {
  const isActive = useAlert((state) => state.isActive)
  const message = useAlert((state) => state.message)

  return (
    isActive &&
    <div className='container px-4 py-4'>
      <div className='col-lg-12 mx-auto' >
        <div className='alert alert-primary text-center rounded-4' dangerouslySetInnerHTML={{ __html: message }}>
        </div>
      </div>
    </div>
  )
}

export default Alert