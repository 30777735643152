import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { NavigationBar, Footer } from '../components/Layout'
import { SearchBar, Feed } from '../features/Home'
import Alert from '../components/Misc/Alert'

const Home = () => {
  
  useEffect(() => {
    document.title = 'MagicFans - Free OnlyFans'
  }, [])

  return (
    <>
      <NavigationBar />
      <Alert />
      <SearchBar />
      <Feed />
      <Footer />
      {/* <div style={{ height: '1000px' }}>
        <h1>test</h1>
      </div> */}
    </>
  )
}

export default Home