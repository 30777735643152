import { create } from 'zustand'

const useAlert = create((set) => ({
    isActive: false,
    message: '',
    setAlert: (message) => set((state) => ({
        isActive: true,
        message: message
    })),
    hideAlert: () => set((state) => ({
        isActive: false
    }))
}))


export default useAlert