import LayoutStyles from '../../assets/css/Layout.css'

const Footer = () => {
  return (
    <footer className="footer mt-auto">
      <div className="py-4">
        <div className="container text-center">
          <p className="mb-0 py-2">© 2024 MagicFans.</p>
          <p>MagicFans provides links to content on the internet. MagicFans does not host any files.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer