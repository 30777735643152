import config from '../../../config'

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import useFiltersParams from '../../../customhooks/useFiltersParams'

import generateDownloadLink from '../api/generateDownloadLink'

import logo from '../../../assets/logo.png'

const Generate = () => {
    const [alert, setAlert] = useState({ active: false, message: '' })
    const { slug_url } = useParams()
    const [getParameterValue, setParameterValue] = useFiltersParams()
    const [cookies, setCookie] = useCookies();

    const handleGenerateLink = async () => {
        if (!slug_url) {
            return window.location.replace('/')
        }

        const adFree = getParameterValue('ad_free', 'false');

        const response = await generateDownloadLink(slug_url, adFree, cookies.sessionId)

        if (response.data.error) {
            return setAlert({ active: true, message: response.data.message })
        }

        //console.log(response.data.download_link)
        window.location.replace(response.data.download_link)
    }

    useEffect(() => {
        handleGenerateLink()
    }, [])

    return (
        <div className="min-vh-100 d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid auth-wrapper text-center" style={{ maxWidth: '500px' }}>
                <img src={logo} className="mb-3" alt="logo" width="202.8" height="39" />

                {alert.active &&
                    <>
                        <div className="alert alert-primary text-center" role="alert">
                            {alert.message}
                        </div>

                        <a className="btn btn-link" href={'/'}>Go Home</a>
                    </>
                }

                {!alert.active &&
                    <>
                        <p>Generating download link...</p>

                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Generate