import config from '../../../config'

import React from 'react'

const AuthButtons = () => {
    return (
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
                <li className="btn btn-primary nav-item dropdown">
                    <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="bi bi-person"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end animate slideIn auth" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href={config.MAGICPOINTS_WEBSITE_URL + '/login?source=magicfans'}>Sign in</a>
                        <a className="dropdown-item" href={config.MAGICPOINTS_WEBSITE_URL + '/register?source=magicfans'}>Create account</a>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default AuthButtons